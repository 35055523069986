
(function () {

   'use strict';

    require('../lib/angular-confirm/angular-confirm');

})();

(module || {}).exports = 'app.vendors';
